const Tour = {
  STATUS_PENDING: 'pending',
  STATUS_COMPLETED: 'completed',
  STATUS_NO_SHOW: 'no_show',
  STATUS_CANCELLED_SURRENDERED: 'cancelled_surrendered',
  STATUS_SCHEDULED_TAKEN_OVER: 'scheduled_taken_over',
  STATUS_TECHNICAL_ISSUE: 'technical_issue',
  STATUS_CANCELLED_BY_AGENT: 'cancelled_by_agent',
  STATUS_CANCELLED_BY_LEAD: 'cancelled_by_lead',
  STATUS_CANCELLED_BY_LEAD_RESCHEDULED: 'cancelled_by_lead_rescheduled',
  STATUS_CANCELLED_NO_LEAD_CONFIRMATION: 'cancelled_no_lead_confirmation',
  STATUS_CANCELLED_BY_ADMIN: 'cancelled_by_admin',
  STATUS_CANCELLED_BY_PM: 'cancelled_by_pm',
  STATUS_CANCELLED_BY_TECHNICAL_ISSUE: 'cancelled_by_technical_issue',
  STATUS_CANCELLED_SHOWING_STOPPED: 'cancelled_showing_stopped',
  STATUS_CANCELLED_SHOWING_PAUSED: 'cancelled_showing_paused',
  STATUS_CANCELLED_SHOWING_WINDOWS_CHANGED: 'cancelled_showing_windows_changed',
  STATUS_CANCELLED_RESCHEDULED: 'cancelled_rescheduled',
  STATUS_CANCELLED_UNMATCHED_BY_ADMIN: 'cancelled_unmatched_by_admin',
};

export default Tour;
