import { axios } from '@/plugins/Axios';

const bareAxios = require('axios').default;

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    register,
    getCodeboxes,
    getDailyCode,
    setCodeboxName,
    mockRegister,
    getCodeByDate,
  },
};

async function register(context, payload) {
  const baseUrl = 'https://rest.codeboxinc.com';
  return await bareAxios.post(`${baseUrl}/authentication`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

async function getCodeboxes() {
  return await axios.get('pm/codeboxes');
}

async function getDailyCode(context, { serialNumber }) {
  return await axios.get(`pm/codeboxes/${serialNumber}/daily-code`);
}

async function getCodeByDate(context, { serialNumber, date }) {
  return await axios.get(`pm/codeboxes/${serialNumber}/code-by-date/${date}`);
}

async function setCodeboxName(context, { serialNumber, name }) {
  return await axios.put(`pm/codeboxes/${serialNumber}`, {
    name,
  });
}

async function mockRegister(context, { businessId, username, password }) {
  return await axios.post(`businesses/${businessId}/mock-codeboxes/registration`, {
    username,
    password,
  });
}
