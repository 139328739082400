<template>
  <header
    v-if="user && $store.getters['Auth/accountIsInactive']"
    id="account-inactive-header"
  >
    <div class="font-17 font-weight-bold">
      <i class="sdicon-exclamation-triangle" />
      Account Inactive
    </div>
    <div>
      Your Showdigs subscription ended on {{ subscriptionEndedAt }}.
      <template v-if="user.role !== Role.ACCOUNT_OWNER">
        Your account owner, {{ user.first_business_user_email }} may renew it.
      </template>
    </div>
    <router-link
      v-if="user.role === Role.ACCOUNT_OWNER"
      :to="{ name: 'app.subscribe' }"
      class="underline text-gray-darker"
    >
      Renew your subscription here
    </router-link>
  </header>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import get from 'lodash.get';
import momentUtil from '@/utils/MomentUtil';
import Role from '@/constants/Role';

export default {
  name: 'SdAccountInactiveHeader',
  setup(props, context) {
    const { $store } = context.root;
    const user = computed(() => {
      // this check is required since user store default state is an object with businesses array
      if (!get($store.state.Auth.user, 'id')) {
        return null;
      }
      return $store.state.Auth.user;
    });

    const subscriptionEndedAt = ref();

    if ($store.getters['Auth/accountIsInactive']) {
      subscriptionEndedAt.value = momentUtil($store.getters['Auth/lastSubscription'].billing_cycle_end_at, momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear();
    }

    return {
      user,
      subscriptionEndedAt,
      Role,
    };
  },
};
</script>

<style lang="scss" scoped>
#account-inactive-header {
  width: 100%;
  padding: 1.5rem 0;
  background: theme-color('yellow-light');
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
