import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {
    payload: {},
  },
  getters: {
    payload: (state) => state.payload,
  },
  mutations: {
    updatePayload(state, newPayload) {
      const payload = state.payload;
      Object.keys(newPayload).forEach((key) => {
        if (key in defaultPayload) {
          payload[key] = newPayload[key];
        }
      });
      state.payload = payload;
    },
  },
  actions: {
    async updatePayload(context, payload) {
      context.commit('updatePayload', payload);
    },
    async updateAndCommitPayload({ commit, dispatch }, data) {
      dispatch('updatePayload', data.payload);
      const response = await axios.put('pm/business-settings', data);
      commit('Auth/setBusiness', response, { root: true });
      return response;
    },
  },
};

const defaultPayload = {
  name: null,
  billing_email: null,
  new_billing_email: null,
  is_concurrent_tours_allowed: null,
  identity_verification: null,
  contact_email: null,
  contact_phone: null,
  contact_phone_extension: null,
  should_show_contact: null,
  video_tours_enabled: null,
  codebox_integration_enabled: null,
  zillow_company_id: null,
  zillow_syndication_enabled_at: null,
  zumper_syndication_enabled_at: null,
  apartments_dot_com_syndication_enabled_at: null,
  codebox_connected: null,
  codebox_names: null,
  screening_criteria_url: null,
  default_screening_criteria: null,
  default_application_form_url: null,
  default_general_notes: null,
  schedule_notice_limit: null,
  address: null,
  logo: null,
  logo_url: null,
  website_url: null,
  show_listing_apply_button: null,
  self_showing_contractor_lockbox_enabled_at: null,
};
