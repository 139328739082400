export const activityTypes = {
  NEW_INQUIRY: 'NEW_INQUIRY',
  INQUIRY_CREATED: 'INQUIRY_CREATED',
  OUTGOING_MESSAGE: 'OUTGOING_MESSAGE',
  INCOMING_MESSAGE: 'INCOMING_MESSAGE',

  DISQUALIFIED: 'DISQUALIFIED',
  QUALIFIED: 'QUALIFIED',

  TOUR_SCHEDULED: 'TOUR_SCHEDULED',
  TOUR_SCHEDULED_SELF_SHOWING: 'TOUR_SCHEDULED_SELF_SHOWING',
  TOUR_COMPLETED: 'TOUR_COMPLETED',
  TOUR_COMPLETED_SELF_SHOWING: 'TOUR_COMPLETED_SELF_SHOWING',
  TOUR_NO_SHOW: 'TOUR_NO_SHOW',
  TOUR_TECHNICAL_ISSUE: 'TOUR_TECHNICAL_ISSUE',
  TOUR_CANCELLED: 'TOUR_CANCELLED',
  TOUR_TAKEN_OVER: 'TOUR_TAKEN_OVER',
  TOUR_SURRENDERED: 'TOUR_SURRENDERED',
  TOUR_NO_AGENT_FOUND: 'TOUR_NO_AGENT_FOUND',

  LEAD_QUALIFIED: 'LEAD_QUALIFIED',
  LEAD_DISQUALIFIED: 'LEAD_DISQUALIFIED',
  QUALIFIED_LEAD: 'QUALIFIED_LEAD',
  DISQUALIFIED_LEAD: 'DISQUALIFIED_LEAD',
  INCOMING_CALL: 'INCOMING_CALL',
  PROPERTY_DELETED: 'PROPERTY_DELETED',
  SHOWING_ACTIVATED: 'SHOWING_ACTIVATED',
  SHOWING_PAUSED: 'SHOWING_PAUSED',
  SHOWING_FUTURE_PAUSED: 'SHOWING_FUTURE_PAUSED',
  SHOWING_FUTURE_PAUSED_ACTIVATED: 'SHOWING_FUTURE_PAUSED_ACTIVATED',
  SHOWING_PAUSED_UPDATE: 'SHOWING_PAUSED_UPDATE',
  SHOWING_UNPAUSED: 'SHOWING_UNPAUSED',
  SHOWING_PAUSE_OVER: 'SHOWING_PAUSE_OVER',
  SHOWING_LEASED: 'SHOWING_LEASED',
  SHOWING_STOPPED: 'SHOWING_STOPPED',
  SHOWING_SWAP: 'SHOWING_SWAP',
  OWNER_REPORTED: 'OWNER_REPORTED',
  SHOWING_REACTIVATED: 'SHOWING_REACTIVATED',
  TOUR_BUDGET_REACHED: 'TOUR_BUDGET_REACHED',
  DELETED_INQUIRY: 'DELETED_INQUIRY',
  IDENTIFICATION_VERIFICATION: 'IDENTIFICATION_VERIFICATION',
};

export const activitySortTypes = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};
