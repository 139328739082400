import { axios } from '@/plugins/Axios';

export default {
  namespaced: true,
  state: {},
  actions: {
    get,
    setDevice,
    sendDeviceCode,
  },
};

async function get() {
  return await axios.get('pm/devices');
}

async function setDevice(context, { serialNumber, name, unitId }) {
  return await axios.put(`pm/devices/${serialNumber}`, {
    name,
    unit_id: unitId,
  });
}

async function sendDeviceCode(context, {
 serialNumber, email, phone_number, code, address, access_date,
}) {
  return await axios.post(`pm/devices/${serialNumber}`, {
    email,
    phone_number,
    code,
    address,
    access_date,
  });
}
